.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  display: inline;
  padding: 0;
  text-decoration: none;
  border: none;
  box-shadow: none;
  color: blue;
  font-size: 1em;
}

.small {
  font-size: 0.7em;
}

.App-link:focus {
  outline: none;
}

.App-link:hover {
  text-decoration: underline;
  cursor: pointer;
}


nav ul > li {
  display: inline-block;
  padding: 0 0.3rem;
}

nav ul {
  padding: 0;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

nav a.active {
  text-decoration: underline;
  cursor: default;
}

nav a.active:hover {
  color: black;
}

nav a:hover {
  color: #aaa;
}

.budgets-container ul {
  padding: 0;
}

.budgets-container li {
  list-style: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding: 0 10rem 2rem;
}

.xact-table-head {
  display: grid;
  grid-template-columns: auto 15rem;
  grid-template-rows: auto;
}

.xact-table-head .paginator {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.xact-table-head .filters {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.packed-button {
  display: inline-block;
  padding: 0.3rem;
  margin: 0.1rem;
}

.xact-table {
  width: 100%;
  font-family: monospace;
}

.xact-table th {
  text-align: left;
}

.xact-table tbody tr:nth-child(odd) {
  background-color: #ddd;
}

.xact-table .date {
  width: 10%;
}

.xact-table .amount {
  width: 7%;
}

.xact-table .account {
  width: 16%;
}

.xact-table .description {
  width: 25%;
}

.xact-table .budget {
  width: 42%;
}

.xact-table td.amount {
  text-align: right;
}

.xact-table {
  padding-bottom: 2rem;
}

.paginator {
  user-select: none;
  text-align: right;
}

.budget-button {
  background-color: lightyellow;
  border: 1px solid black;
  cursor: pointer;
}

.budget-button.selected {
  background-color: lightblue;
}

.budget-button.hidden {
  visibility: hidden;
}

.invalid {
  color: red;
}

.bill-heading, .spending-summary-item {
  display: grid;
  grid-template-columns: 3.5rem 12rem 8.5rem 4.5rem 5rem auto;
  grid-template-rows: auto;
  padding: 0.2rem 0;
}

.bill-heading .expand {
  grid-column: 1 / 2;
}

.bill-heading .account-name {
  grid-column: 2 / 3;
}

.bill-heading .dates {
  grid-column: 3 / 5;
  text-align: right;
}

.bill-heading .controls {
  grid-column: 5 / 6;
}

.bill-heading input[type="text"] {
  width: 5rem;
}

.spending-summary-item.total {
  padding-top: 0.4rem;
}

.spending-summary-item .budget {
  grid-column: 2 / 3;
}

.spending-summary-item .amount {
  grid-column: 4 / 5;
  text-align: right;
  font-family: monospace;
}

.spending-summary-item.total .amount {
  border-top: 1px solid black;
  padding-top: 0.4rem;
}